@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: 'gill-sans', ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
    'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
    'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  font-family: 'Gill sans';
  font-weight: 300;
  max-width: 100vw;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}


video::-webkit-media-controls-panel {
	display: none !important;
	-webkit-appearance: none;
}

video::-webkit-media-controls-play-button {
	display: none !important;
	-webkit-appearance: none;
}

video::-webkit-media-controls-start-playback-button {
	display: none !important;
}

video {
  pointer-events: none;
}


